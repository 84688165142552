import Swiper from "swiper/bundle";

jQuery(function ($) {
  let $urgencePos = $(".header__urgence").offset().top;

  console.log($urgencePos);

  let $scroll = $(window).scrollTop();

  console.log($scroll);

  $(window).scroll(function () {
    if ($(window).scrollTop() > 50) {
      $(".header__urgence").addClass("active");
      $(".header__menu").addClass("active");
    } else {
      $(".header__urgence").removeClass("active");
      $(".header__menu").removeClass("active");
    }
  });

  // ------------------------------------------- SWIPER
  // ------------------------------------------- SLIDE
  $(window).load(function () {
    const swiper = new Swiper(".swiper__slide", {
      slidesPerView: 1,
      loop: true,
      // loopAdditionalSlides: 20,
      // freeMode: true,
      // spaceBetween: 5,
      speed: 900,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        // el: ".swiper-pagination",
        // clickable: true,
      },
      navigation: {
        // nextEl: ".swiper-button-next",
        // prevEl: ".swiper-button-prev",
      },
    });

    $(".swiper__slide .swiper-slide").hover(
      function () {
        swiper.autoplay.stop();
      },
      function () {
        swiper.autoplay.start();
      }
    );

    const swiperCat = new Swiper(".swiper__categories", {
      slidesPerView: "auto",
      roundLengths: true,
      loop: true,
      loopedSlides: 10,
      centeredSlides: true,
      spaceBetween: 30,
      speed: 400,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const swiper2 = new Swiper(".swiper__realisations", {
      slidesPerView: "auto",
      loop: true,
      // freeMode: true,
      spaceBetween: 25,
      centeredSlides: true,
      roundLengths: true,
      loopAdditionalSlides: 30,
      speed: 700,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    $(".swiper__realisations .swiper-slide").hover(
      function () {
        swiper2.autoplay.stop();
      },
      function () {
        swiper2.autoplay.start();
      }
    );

    const swiper3 = new Swiper(".swiper__description", {
      slidesPerView: 1,
      loop: true,
      // loopAdditionalSlides: 20,
      // freeMode: true,
      // spaceBetween: 5,
      speed: 900,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        // el: ".swiper-pagination",
        //   clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    $(".swiper__description .swiper-slide").hover(
      function () {
        swiper3.autoplay.stop();
      },
      function () {
        swiper3.autoplay.start();
      }
    );
  });
});
