import "../sass/main.scss";

import "./navigation";
import "./home";
import "./utils";
import "./form";
import "./administration";
import "./menu_category";
import "./product";
//import "./modal";
// import "./jquery.appear";

import SmoothScroll from "smooth-scroll";
import { Autoplay } from "swiper";

import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";
import PhotoSwipe from "photoswipe/dist/photoswipe.esm.js";
import "photoswipe/dist/photoswipe.css";

const options = {
  gallerySelector: "#gallery",
  childSelector: "a",
  pswpModule: PhotoSwipe,
};
const lightbox = new PhotoSwipeLightbox(options);
lightbox.init();

// import appear from "jquery-appear-original";

// var appear = require("jquery-appear-original");

jQuery(document).ready(function ($) {
  // $(".timer").countTo();

  // $.appear(".timer");

  $(window).load(function () {
    const player = new Plyr("#player", {
      controls: [
        "play-large",
        "play",
        "progress",
        "mute",
        "volume",
        "fullscreen",
      ],
      volume: 0,
      muted: true,
    });
  });

  // player.play();

  $(".home-avantages").appear(function () {
    $(".timer").countTo();
  });

  $(window).load(function () {
    $("#before-after").twentytwenty();
  });

  // ================================================== LOADER
  $(window).load(function () {
    $("#status").fadeOut();
    $("#preloader").delay(350).fadeOut(500);
    $("body").delay(350).css({
      overflow: "visible",
    });
  });
  // ================================================== CLOSE ALERT
  $(".close").click(function () {
    $(".alert").hide();
  });

  // ================================================== SMOOTH
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
  });
});
